<template>
  <div style="background: #fff; padding-bottom: 40px; width: 100%">
    <!-- 单选框区域 -->
    <div class="radio-title">是否中标</div>
    <el-radio-group
      :value="formData.isWin"
      style="margin-bottom: 20px; padding: 0 20px"
      @input="onRadioChange"
      :disabled="!canWrite"
    >
      <el-radio label="loser">未中标</el-radio>
      <el-radio label="winner"> 已中标 </el-radio>
    </el-radio-group>
    <!-- 已中标附件上传区域 -->
    <!-- <UploadList
      style="min-height: 500px"
      v-model="uploadData"
      fileCode="user_fj"
      v-show="formData.isWin == 'winner'"
      :staffId="formData.fkId"
      :disabled="!canWrite"
    ></UploadList> -->
    <!-- 未中标 -->
    <el-form
      ref="form"
      v-if="formData.isWin == 'loser' && formData.bidAnalysisResp"
      :model="formData.bidAnalysisResp"
      :rules="rules"
    >
      <!-- 必填内容 -->
      <el-descriptions direction="vertical" :column="2" border style="width: 100%">
        <el-descriptions-item :span="2">
          <template slot="label">
            <span style="color: red">*</span>
            失败原因
          </template>
          <el-form-item prop="failureReason" ref="failureReason" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.failureReason"
              type="textarea"
              placeholder="请输入失败原因"
              :disabled="!canWrite"
              :autosize="{ minRows: 2, maxRows: 999 }"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <span style="color: red">*</span>
            中标企业
          </template>
          <el-form-item prop="competitor" ref="competitor" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.competitor"
              placeholder="请输入中标企业"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <span style="color: red">*</span>
            中标金额
          </template>
          <el-form-item prop="bidAmount" ref="bidAmount" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.bidAmount"
              placeholder="请输入中标金额"
              @blur="blurInput('bidAmount')"
              type="number"
              class="numrule"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            对方优势
          </template>
          <el-form-item prop="competitorAdvantage" ref="competitorAdvantage" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.competitorAdvantage"
              type="textarea"
              placeholder="请输入对方优势"
              :disabled="!canWrite"
              :autosize="{ minRows: 2, maxRows: 999 }"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            对方弱点
          </template>
          <el-form-item prop="competitorWeakness" ref="competitorWeakness" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.competitorWeakness"
              placeholder="请输入对方弱点"
              :disabled="!canWrite"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 999 }"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            我们的报价
          </template>
          <el-form-item prop="ourPrice" ref="ourPrice" style="width: 100%">
            <el-input
              v-model="formData.bidAnalysisResp.ourPrice"
              placeholder="请输入我们的报价"
              @blur="blurInput('ourPrice')"
              type="number"
              class="numrule"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            对方的报价
          </template>
          <el-form-item prop="competitorPrice" ref="competitorPrice" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.competitorPrice"
              placeholder="请输入对方的报价"
              @blur="blurInput('competitorPrice')"
              type="number"
              class="numrule"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            服务内容和技术方案
          </template>
          <el-form-item prop="serviceContent" ref="serviceContent" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.serviceContent"
              placeholder="请输入服务内容和技术方案"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 999 }"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item :span="1">
          <template slot="label">
            <!-- <span style="color: red">*</span> -->
            未达成的指标
          </template>
          <el-form-item prop="unmetIndicators" ref="unmetIndicators" style="width: 100%">
            <el-input
              v-model.trim="formData.bidAnalysisResp.unmetIndicators"
              placeholder="请输入未达成的指标"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 999 }"
              :disabled="!canWrite"
            ></el-input>
          </el-form-item>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <UploadList
      style="min-height: 500px"
      v-model="uploadData"
      fileCode="user_fj"
      :staffId="formData.fkId"
      :disabled="!canWrite"
    ></UploadList>
  </div>
</template>

<script>
import { getInstanceByDom } from 'echarts'
export default {
  name: 'WinResult',
  components: {
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    //当前页面输入框输入状态
    editType: {
      type: String,
      default: '',
    },
  },
  watch: {
    detailData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.formData.isWin = newVal.isWin
        this.formData.fkId = newVal.fkId
        this.formData.bidAnalysisResp = newVal.bidAnalysisResp || {}
        // 将详情中的附件数据传入uploadData
        this.getDetailFileList(newVal)
        if (this.editType == 'add') {
          this.formData.bidAnalysisResp = {
            failureReason: null,
            competitor: null,
            competitorAdvantage: null,
            competitorWeakness: null,
            ourPrice: null,
            competitorPrice: null,
            serviceContent: null,
            unmetIndicators: null,
          }
        }
      },
    },
  },
  computed: {
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'bidEdit':
          return false
        default:
          return false
      }
    },
  },
  data() {
    return {
      formData: {
        isWin: '',
        fkId: '',
        bidAnalysisResp: {},
      },
      uploadData: [],
      rules: {
        failureReason: [{ required: true, message: '请输入失败原因', trigger: 'blur' }],
        competitor: [{ required: true, message: '请输入中标企业', trigger: 'blur' }],
        bidAmount: [{ required: true, message: '请输入中标金额', trigger: 'blur' }],
      },
    }
  },
  methods: {
    blurInput(e) {
      this.formData.bidAnalysisResp[e] = Number(this.formData.bidAnalysisResp[e]).toFixed(2)
    },
    onRadioChange(e) {
      this.formData.isWin = e
      // if (this.uploadData.length > 0) {
      //   this.uploadData = []
      // }
    },
    getDetailFileList(newVal) {
      if (newVal.fileList && newVal.fileList.length > 0) {
        let detailFileList = newVal.fileList.map(item => {
          return {
            attachmentName: item.fileName,
            attachmentPath: item.filePath,
            id: item.id,
            remark: item.remark,
            type: item.type,
            staffId: newVal.fkId,
            bidType: item.bidType,
          }
        })
        this.uploadData = [...detailFileList]
      }
    },
    verifySave() {
      let show = true
      if (this.formData.isWin == 'loser') {
        this.$refs.form.validate(valid => {
          if (valid) {
            show = true
          } else {
            show = false
          }
        })
      } else {
        show = true
      }
      return show
    },
    save() {
      // if (this.formData.isWin == 'winner' && this.uploadData.length == 0) {
      //   this.$message.warning('请上传附件')
      //   return 'warning'
      // }
      //过滤出被删除的附件数组
      let deleteArr = (this.detailData.fileList || [])
        .filter(
          item =>
            !this.uploadData
              .filter(x => x.id)
              .map(y => y.id)
              .includes(item.id)
        )
        .map(item => item.id)
      let fileList = this.uploadData.map(item => {
        return {
          fkId: item.staffId,
          fileName: item.attachmentName,
          filePath: item.attachmentPath,
          id: item.id,
          remark: item.remark,
          type: 'ZBFJ',
          bidType: 'bidFile',
        }
      })
      this.formData.fileList = fileList
      this.formData.deleteFileList = deleteArr
      return this.formData
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0 5px;
}
/deep/.el-descriptions .el-descriptions-item__cell {
  padding-bottom: 0px;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
